























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { PublicVoucher } from '@/domain/entities/PublicVoucher'
import Badge from '@/app/ui/components/Badge/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import { VOUCHER_PURPOSES_EDIT } from '@/app/infrastructures/misc/Constants/publicVoucher'

@Component({
  components: {
    Badge,
  },
})
export default class PublicVoucherItem extends Vue {
  @Prop() private isGuest!: boolean
  @Prop() private publicVoucher!: Record<string, unknown>
  @Prop() private isMultipleVoucher!: boolean

  get detailVoucher(): PublicVoucher {
    return this.publicVoucher
  }

  get statusBadgeType(): string {
    if (
      this.detailVoucher?.status?.toLowerCase() === 'expired' ||
      this.detailVoucher?.status?.toLowerCase() === 'cancelled'
    ) {
      return 'error'
    } else if (
      this.detailVoucher?.status?.toLowerCase() === 'active' ||
      this.detailVoucher?.status?.toLowerCase() === 'published'
    ) {
      return 'success'
    } else {
      return 'warning'
    }
  }

  get isPendingOrActive(): boolean {
    return (
      this.detailVoucher.status === 'ACTIVE' ||
      this.detailVoucher.status === 'PENDING'
    )
  }

  get getVoucherItemStatus(): string {
    return Utils.toCapitalize(this.detailVoucher.status || '-')
  }

  get voucherPurposeName(): string {
    return VOUCHER_PURPOSES_EDIT.find(
      (item) => item.value === this.detailVoucher.purpose
    )?.label || '-'
  }

  private getPeriod(startDate: string, endDate: string) {
    return `${Utils.formatDate(startDate, 'DD MMMM YYYY')} - ${Utils.formatDate(
      endDate,
      'DD MMMM YYYY'
    )}`
  }

  private formatDate(date: string) {
    return Utils.formatTimeZone(Utils.formatDate(date, 'DD MMMM YYYY HH:mm Z'))
  }

  private toVoucherUsage() {
    if (this.isMultipleVoucher) {
      return {
        name: 'PublicVoucherMultipleCodePage',
        query: { publicVoucherId: this.detailVoucher.id },
      }
    }
    return {
      name: 'PublicVoucherUsagePage',
      params: { id: this.detailVoucher.id },
    }
  }
}
