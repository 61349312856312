









































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { PublicVoucher as PublicVoucherEntity } from '@/domain/entities/PublicVoucher'
import controller from '@/app/ui/controllers/PublicVoucherController'
import PublicVoucherItem from '../components/PublicVoucherItem/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import { Utils } from '@/app/infrastructures/misc'
import { PUBLIC_VOUCHER_PAGINATION } from '@/app/infrastructures/misc/Constants'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import Tabs from '@/app/ui/components/Tabs/index.vue'

type fakeDictionary<T> = { [key: string]: T }
@Component({
  components: {
    ModalAction,
    PublicVoucherItem,
    Button,
    TextInput,
    DropdownSelect,
    PaginationNav,
    Loading,
    Modal,
    EmptyState,
    Tabs,
  },
})
export default class PublicVoucher extends Vue {
  isGuest = Utils.isGuest()
  selectedItem = new PublicVoucherEntity()
  confirmationModal = false
  successModal = false
  controller = controller
  filterOptions = [
    { label: 'Show All', value: 'SHOW_ALL' },
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Pending', value: 'PENDING' },
    { label: 'Cancelled', value: 'CANCELLED' },
    { label: 'Expired', value: 'EXPIRED' },
  ]
  sortOptions = [
    { label: 'Newest', value: 'NEWEST' },
    { label: 'Oldest', value: 'OLDEST' },
  ]
  parameters = {
    page: 1,
    perPage: PUBLIC_VOUCHER_PAGINATION,
    keyword: null,
    sortBy: this.sortOptions[0],
    filterBy: this.filterOptions[0],
    isMultiple: false,
  }

  currentTab = 0
  itemTabs = [
    { label: 'Single Voucher', value: 0 },
    { label: 'Multiple Voucher', value: 1 }
  ]

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      if (queries.isMultiple === 'true') {
        this.currentTab = 1
      }
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage:
          Utils.alwaysNumber(queries.perPage) || PUBLIC_VOUCHER_PAGINATION,
        keyword: queries.keyword,
        sortBy:
          this.sortOptions.find(item => item.value === queries.sortBy) ||
          this.sortOptions[0],
        filterBy:
          this.filterOptions.find(item => item.value === queries.filterBy) ||
          this.filterOptions[0],
        isMultiple: this.currentTab == 1,
      }
    }

    this.fetchPublicVoucher()
  }

  private onTabsClick(tab: number): void {
    this.currentTab = tab
    this.parameters = {
      page: 1,
      perPage: PUBLIC_VOUCHER_PAGINATION,
      keyword: null,
      sortBy: this.sortOptions[0],
      filterBy: this.filterOptions[0],
      isMultiple: tab === 1,
    }
    this.fetchPublicVoucher(true)
  }

  @Watch('parameters.keyword')
  onKeywordChanged(val: string): void {
    val === '' && this.fetchPublicVoucher(true)
  }

  @Watch('params')
  onParamsChanged(
    val: fakeDictionary<string | (string | null)[] | null | undefined>
  ): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  get params() {
    return {
      ...this.parameters,
      sortBy: this.parameters.sortBy.value,
      filterBy: this.parameters.filterBy.value,
    }
  }

  private cancelVoucher(): void {
    controller.deletePublicVoucher({
      publicVoucherId: `${this.selectedItem.id}`,
    })
    this.confirmationModal = false
  }

  @Watch('controller.isDeleteSuccess')
  onWatchDeleteVoucher(isSuccess: boolean): void {
    if (isSuccess) {
      this.successModal = true
    }
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.fetchPublicVoucher(true)
  }

  private fetchPublicVoucher(resetPage?: boolean): void {
    if (resetPage) {
      this.parameters.page = 1
    }
    controller.getPublicVoucherList(this.params)
  }

  beforeDestroy(): void {
    controller.setDeleteSuccess(false)
  }
}
